
import { defineComponent, ref } from 'vue'
import type { UploadUserFile } from 'element-plus'
import CloseButton from '@/components/common/buttons/CloseButton.vue'
import SaveButton from '@/components/common/buttons/SaveButton.vue'
import useUploadRepositories from '@/repositories/useUploadRepositories'

export default defineComponent({
  components: { CloseButton, SaveButton },
  props: {
    title: {
      type: String,
      required: true,
      default: 'Modal Dialog',
    },
  },
  setup(props, context) {
    const dialogVisible = ref(false)
    const fileList = ref<UploadUserFile[]>([])

    const { onUploadImage } = useUploadRepositories()
    const onSave = async () => {
      const uploadedFilesPromises = fileList.value.map(async file => {
        if (file.raw) {
          const uploadedFile = await onUploadImage(file.raw)
          if (uploadedFile) {
            return {
              title: file.name,
              filePath: uploadedFile.path,
              contentType: file.raw?.type,
            }
          }
        }
        return null
      })

      const uploadedFiles = await Promise.all(uploadedFilesPromises)
      const filteredUploadedFiles = uploadedFiles.filter(file => file !== null)

      context.emit('onAfterUploadImages', filteredUploadedFiles)
      onClose()
    }

    const toggle = () => {
      fileList.value = []
      dialogVisible.value = true
    }
    const onClose = () => {
      dialogVisible.value = false
    }
    return { dialogVisible, toggle, onClose, fileList, onSave }
  },
})
