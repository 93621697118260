
import { computed, reactive, watch, ref, defineComponent } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useFetchData } from '@/composables'
import CreateOrUpdate from './components/CreateOrUpdate.vue'
import { imageUrl } from '@/utils/image'
import useImageLibraryRepositories from '@/repositories/useImageLibraryRepositories'
import { useNotification } from '@/composables'
import { formatTime } from '@/utils/dateTime'
import FilterTable from '@/components/common/FilterTable.vue'
import Pagination from '@/components/common/Pagination.vue'
import { CopyDocument } from '@element-plus/icons'
import { copyToClipboardV2 } from '@/utils/string'
import EditRowButton from '@/components/common/buttons/EditRowButton.vue'
import DeleteRowButton from '@/components/common/buttons/DeleteRowButton.vue'
import SlotError from '@/components/common/SlotError.vue'
import { downloadFile } from '@/utils/file'
import UploadMultiFiles from './components/UploadMultiFiles.vue'
import { isAgencyManager } from '@/utils/orderUtils'

export default defineComponent({
  components: {
    sectionLayoutContent,
    CreateOrUpdate,
    FilterTable,
    Pagination,
    CopyDocument,
    EditRowButton,
    DeleteRowButton,
    SlotError,
    UploadMultiFiles,
  },
  setup() {
    const breadcrumbs = ['Image Library', '']
    // const baseResource = 'http://localhost:3001/api/images'
    const baseResource = 'images'

    const content = computed(() => {
      return {
        breadcrumbs,
        titleCreate: 'Upload One',
        iconCreate: 'fas fa-cloud-upload-alt',
        titleExport: 'Export',
        titleOtherButton1: 'Upload Multiple Files',
        iconOtherButton1: 'fas fa-cloud-upload-alt',
      }
    })

    const { del, exportData, uploadMultipleImages } =
      useImageLibraryRepositories()
    const { success, error } = useNotification()

    const isManager = ref<boolean>(false)
    const userInfo = JSON.parse(localStorage?.getItem('info') || '')
    isManager.value = isAgencyManager(userInfo)

    const onDelete = async (row: any) => {
      if (row && row._id) {
        const res = await del(row._id)
        if (res?.status === 200 || res?.status === 201) {
          success('Delete success')
          fetchData()
        } else {
          error('Delete fail')
        }
      }
    }

    const onFilterCallback = (filter: any) => {
      params.search = filter.search
    }

    let loading = ref(false)

    const params = reactive({ search: '', page: 1, limit: 10 })

    const { documents, fetchData, isLoading, total } = useFetchData(
      baseResource,
      params
    )
    loading = isLoading
    fetchData()

    watch(params, fetchData)

    const columns = [
      {
        key: 'id',
        title: 'Title',
        dataIndex: 'title',
        minWidth: '30',
      },
      {
        title: 'Preview',
        dataIndex: 'filePath',
        minWidth: '15',
        // align: 'center',
      },
      {
        title: 'Type',
        dataIndex: 'contentType',
        minWidth: '10',
      },
      {
        title: 'Created',
        dataIndex: 'createdAt',
        minWidth: '10',
      },
      {
        title: 'Actions',
        dataIndex: 'action',
        minWidth: '10',
        align: 'center',
      },
    ]

    const uploadLibraryImageDialog = ref<InstanceType<typeof CreateOrUpdate>>()
    const createImageLibrary = () => {
      uploadLibraryImageDialog.value?.toggle('')
    }

    const updateImageLibrary = (entity: any) => {
      uploadLibraryImageDialog.value?.toggle(entity.id)
    }

    const exportImageLibrary = async () => {
      if (total.value === 0) {
        error('No data to export')
        return
      }
      const paramsExport = {
        search: params.search,
      }
      const res: any = await exportData(paramsExport)
      if (res?.status === 200 || res?.status === 201) {
        success('Export success')
        downloadFile(res.data?.url)
        fetchData()
      } else {
        error('Export fail')
      }
    }

    const uploadMultiFilesDialog = ref<InstanceType<typeof UploadMultiFiles>>()
    const uploadMultipleFiles = () => {
      uploadMultiFilesDialog.value?.toggle()
    }

    const onAfterCreate = () => {
      fetchData()
    }

    const onAfterUploadImages = async (uploadedFiles: any) => {
      const res: any = await uploadMultipleImages(uploadedFiles)
      if (res?.status === 200 || res?.status === 201) {
        success('Upload success')
        fetchData()
      } else {
        error('Upload fail')
      }
    }

    return {
      onAfterUploadImages,
      uploadMultiFilesDialog,
      uploadMultipleFiles,
      params,
      content,
      documents,
      isLoading,
      loading,
      total,
      columns,
      uploadLibraryImageDialog,
      createImageLibrary,
      updateImageLibrary,
      imageUrl,
      onDelete,
      formatTime,
      onFilterCallback,
      copyToClipboardV2,
      exportImageLibrary,
      isManager,
      onAfterCreate,
    }
  },
})
