import { reactive } from 'vue'
import { useFetchData } from '@/composables'
import { useResource } from '@/services'
import axios from '@/services/axios'
import { AxiosResponse } from 'axios'

export default function useImageLibraryRepositories() {
  const resource = `/images`
  const { get } = useResource(resource)

  const state = reactive<{
    total: any
    images: any[]
  }>({
    total: 0,
    images: [],
  })

  const search = async (params: any) => {
    try {
      const { documents, fetchData, total } = useFetchData(resource, params)
      await fetchData()
      state.total = total
      state.images = documents.value as any[]
      return state.images
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getOne = async (id: string) => {
    try {
      const { get } = useResource(resource)
      return await get(id)
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const create = async (image: any): Promise<AxiosResponse<any, any>> => {
    return await axios.post(resource, image)
  }

  const edit = async (
    id: string,
    image: any
  ): Promise<AxiosResponse<any, any>> => {
    return await axios.patch(`${resource}/${id}`, image)
  }

  const del = async (id: string) => {
    try {
      const { deleteOne } = useResource(resource)
      return await deleteOne(id)
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const exportData = async (params: any) => {
    try {
      return await axios.get(`${resource}/search/export`, { params })
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const uploadMultipleImages = async (files: any[]) => {
    return await axios.post(`${resource}/upload-multiple`, files)
  }

  return { exportData, search, getOne, edit, create, del, uploadMultipleImages }
}
